import {Component}        from '@angular/core';
import {RouterOutlet}     from "@angular/router";
import {CommonModule}     from "@angular/common";
import {HttpClientModule} from "@angular/common/http";

@Component({
    selector   : 'app-blog',
    standalone : true,
    imports: [
        CommonModule,
        RouterOutlet,
        HttpClientModule,
    ],
    templateUrl: './blog.component.html',
})
export class BlogComponent {

}
