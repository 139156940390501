<div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0 hidden sm:hidden md:block lg:block">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div class="flex mb-12">
                <div class="flex-grow-1">
                    <div class="flex">
                        <div *ngIf="block.title"
                             [class]="'font-bold text-3xl'"
                             [innerHTML]="block.title | safeHTML"></div>
                        <div class="p-tag p-tag-success font-normal m-auto ml-4">{{block.title_tag}}</div>
                    </div>
                </div>

                <div *ngIf="block.blocks.length > 1">
                    <ul class="surface-0 p-2 m-0 list-none flex column-gap-2 overflow-x-auto select-none surface-border"
                        style="border-radius: 35px">
                        <li *ngFor="let productType of block.blocks; let index = index">
                            <button pRipple
                                    class="p-link cursor-pointer px-4 py-3 flex align-items-center transition-color transition-duration-150"
                                    [ngClass]="{
                                        'bg-primary': selectedProductType == index,
                                        'text-600': selectedProductType != index,
                                        'hover:surface-hover': selectedProductType != index}"
                                    (click)="selectedProductType = index" style="border-radius: 30px">
                                <span class="font-medium">{{ productType.title }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <ng-container *ngFor="let productType of block.blocks; let index = index">
                    <div *ngIf="index == selectedProductType" class="rounded-3xl overflow-x-auto">
                        <table class="w-full table-auto" style="border-collapse: collapse; border-spacing: 0">
                            <thead>
                            <tr class="bg-endeavour-200" style="height: 1px;">
                                <td class="p-3">
                                </td>
                                <td *ngFor="let product of productType.blocks" class="p-3 text-center" style="height: inherit;">
                                    <app-pricing-table-header class="flex h-full w-full items-end" [block]="product" [theme]="theme">
                                    </app-pricing-table-header>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let feature of productType.blocks[0].blocks; let index = index">
                                <tr *ngIf="index > 0" [class]="{
                                            'bg-endeavour-200': feature.type == 'featureSpacing',
                                            'border-secondary-light': feature.type == 'featureSpacing',
                                            'border-bottom-1': feature.type == 'featureSpacing',
                                            'border-top-1': feature.type == 'featureSpacing' && index > 1,
                                            'bg-brand-lightest': feature.type != 'featureSpacing' && (index%2) == 0
                                        }">
                                    <ng-container *ngIf="feature.type == 'featureSpacing'">
                                        <td class="p-3 text-900 font-bold border-200 text-lg" colspan="4" [innerHTML]="feature.title | safeHTML">
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="feature.type == 'feature'">
                                        <td class="p-3 text-900 font-medium border-200 overflow-hidden max-w-[400px]">
                                            <div class="flex items-center gap-4 justify-between">
                                                <div [innerHTML]="feature.title | safeHTML"></div>
                                                <span *ngIf="feature.text" class="text-sm self-end"><i class="pi pi-info-circle text-600 mr-2" tooltipStyleClass="tooltip-custom-width" [pTooltip]="feature.text"></i></span>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="feature.type != 'featureSpacing'">
                                        <ng-container *ngFor="let productFeature of productType.blocks; let findex = index">
                                            <td *ngIf="productType.blocks[findex].blocks[index].content_limited"
                                                class="text-center p-medium border-200"
                                                [innerHTML]="productType.blocks[findex].blocks[index].content_limited | safeHTML"></td>
                                            <td *ngIf="!productType.blocks[findex].blocks[index].content_limited && productType.blocks[findex].blocks[index].icon?.value != 'noIcon'"
                                                class="text-center p-medium border-200">
                                                <app-icon [icon]="productType.blocks[findex].blocks[index].icon?.value"
                                                          class="text-primary-darker text-base"></app-icon>
                                            </td>
                                            <td *ngIf="!productType.blocks[findex].blocks[index].content_limited
                                                        && productType.blocks[findex].blocks[index].icon?.value == 'noIcon'"
                                                class="text-center p-medium border-200"></td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="container m-auto relative sm:block xs:block md:hidden lg:hidden">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>
        <div [class]="'relative'"
             [ngClass]="{'py-7': theme.theme !== 'light'}">

            <div class="flex flex-row flex-wrap px-3 mb-4">
                <div class="w-full mb-1">
                    <div class="p-tag p-tag-success font-normal mb-1 float-left">{{block.title_tag}}</div>
                </div>
                <div class="w-full mb-4">
                    <div class="float-left" *ngIf="block.title"
                         [class]="'font-bold text-3xl'"
                         [innerHTML]="block.title | safeHTML"></div>
                </div>

                <div class="w-full" *ngIf="block.blocks.length > 1">
                    <ul class="surface-0 p-2 m-0 list-none flex column-gap-2 overflow-x-auto select-none surface-border float-left"
                        style="border-radius: 35px">
                        <li *ngFor="let productType of block.blocks; let index = index">
                            <button pRipple
                                    class="p-link cursor-pointer px-1 py-2 flex align-items-center transition-color transition-duration-150"
                                    [ngClass]="{
                                        'bg-primary': selectedProductType == index,
                                        'text-700': selectedProductType != index,
                                        'hover:surface-hover': selectedProductType != index}"
                                    (click)="selectedProductType = index" style="border-radius: 30px">
                                <span class="font-medium text-xs">{{ productType.title }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <ng-container *ngFor="let productType of block.blocks; let index = index">
                <div *ngIf="index == selectedProductType">
                    <div class="w-full white-space-nowrap">
                        <div class="bg-endeavour-200 p-2">
                            <div class="flex flex-row">
                                <div *ngFor="let product of productType.blocks" class="p-1 text-center flex-grow-0 w-1/3">
                                    <div class="flex flex-column">
                                        <div class="text-900 text-left text-xl font-medium mb-3 text-wrap ">{{product.title}}</div>
                                        <div *ngIf="product.title_tag" class="text-[10px]/[12px] text-left font-medium">
                                            <div class="rounded-[6px] border-1 border-primary p-1 text-primary text-wrap">{{product.title_tag}}</div>
                                        </div>
                                        <div class="text-600 font-normal flex flex-wrap">
<!--                                            text-wrap text-left [&>p]:font-xs [&>p]:m-1 [&>p>*]:m-1 [&>p>strong]:text-2xl [&>p>del+strong]:block [&>p>del+strong]:text-primary [&>p>strong]:font-semibold [&>p>del]:text-2xl [&>p>del]:font-semibold [&>p>del]:text-gray-500 [&>p>del]:decoration-gray-500-->
                                            <span class="text-wrap text-left [&>p]:text-xs [&>p>strong]:block [&>p>del+strong]:text-primary [&>p>strong]:text-normal [&>p>strong]:font-bold [&>p>del]:text-normal [&>p>del]:font-bold" [innerHTML]="product.content_limited | safeHTML"></span>
                                            <span *ngIf="product.text" class="text-left flex items-end pb-2.5"><i class="pi pi-info-circle text-600 ml-0.5" [pTooltip]="product.text"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="productType.blocks[0].blocks[0].type ==='productButtonComponent'">
                                <app-button *ngIf="productType.blocks[0].blocks[0].title" [block]="productType.blocks[0].blocks[0]" [theme]="theme"
                                            blockClass="p-button-outlined mx-3"></app-button>
                            </ng-container>
                            <div *ngIf="productType.blocks[0].show_basic" class="mt-2 mx-3 text-sm text-800">
                                {{ 'RECEIVE_ALL' | translate }} <a href="#" class="text-xs text-800 decoration-gray-800">{{ 'BASIC_BENEFITS' | translate }}</a> +
                            </div>
                        </div>
                        <div class="flex-column">
                        <ng-container *ngFor="let feature of productType.blocks[0].blocks; let index = index">
                            <div *ngIf="index > 0" class="flex flex-row flex-wrap justify-content-around" [class]="{
                                            'bg-endeavour-200': feature.type == 'featureSpacing',
                                            'border-secondary-light': feature.type == 'featureSpacing',
                                            'border-bottom-1': feature.type == 'featureSpacing',
                                            'border-top-1': feature.type == 'featureSpacing' && index > 1,
                                            'bg-brand-lightest': feature.type != 'featureSpacing' && (index%2) == 0,
                                            'pb-3': feature.type != 'featureSpacing'
                                        }">
                                <ng-container *ngIf="feature.type == 'featureSpacing'">
                                    <div class="p-3 text-900 font-semibold text-sm border-200 w-full text-wrap" [innerHTML]="feature.title | safeHTML">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="feature.type == 'feature'">
                                    <div class="p-3 text-900 font-medium border-200 w-full flew-grow-3 text-wrap">{{ feature.title }}
                                        <span *ngIf="feature.text" class="text-sm"><i class="pi pi-info-circle text-600 mr-2" [pTooltip]="feature.text"></i></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="feature.type != 'featureSpacing'">
                                    <ng-container *ngFor="let productFeature of productType.blocks; let findex = index">
                                        <div *ngIf="productType.blocks[findex].blocks[index].content_limited"
                                            class="text-center p-medium border-200 flew-grow-1 text-wrap text-800 font-normal text-xs"
                                             [class]="{
                                                'max-w-[50%]': productType.blocks.length == 2,
                                                'max-w-[33%]': productType.blocks.length == 3,
                                                'max-w-[25%]': productType.blocks.length == 4,
                                             }"
                                            [innerHTML]="productType.blocks[findex].blocks[index].content_limited | safeHTML"></div>
                                        <div *ngIf="!productType.blocks[findex].blocks[index].content_limited && productType.blocks[findex].blocks[index].icon?.value != 'noIcon'"
                                            class="text-center p-medium border-200 flew-grow-1 text-800">
                                            <div class="w-[25px]">
                                                <app-icon [icon]="productType.blocks[findex].blocks[index].icon?.value"
                                                          class="text-primary-darker text-base"></app-icon>&nbsp;
                                            </div>
                                        </div>
                                        <div *ngIf="!productType.blocks[findex].blocks[index].content_limited
                                                        && productType.blocks[findex].blocks[index].icon?.value == 'noIcon'"
                                            class="text-center p-medium border-200 w-[25px]"></div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
