import {Component, Inject}                from '@angular/core';
import {Router, RouterLink, RouterOutlet}         from "@angular/router";
import {CommonModule, DOCUMENT, ViewportScroller} from "@angular/common";
import {HttpClient, HttpClientModule}             from "@angular/common/http";
import {ComponentsModule}                 from "../../../components/components.module";
import {Environment, ENVIRONMENT}         from "../../../models/util";
import {environment}                      from "../../../../environments/environment";
import {Page}                             from '../../../components/page';
import {TagModule}                        from "primeng/tag";
import {Meta, Title}                      from "@angular/platform-browser";
import {PageComponentModel}               from "../../../models/page";
import {LocalizeRouterService}            from "@gilsdav/ngx-translate-router";
import {TranslateService}                 from "@ngx-translate/core";
import {GoogleTagManagerService}          from "../../../services/gtm.service";

@Component({
    selector   : 'app-blog',
    standalone : true,
    imports    : [
        RouterLink,
        CommonModule,
        RouterOutlet,
        HttpClientModule,
        ComponentsModule,
        TagModule,
    ],
    providers  : [
        {
            provide : ENVIRONMENT,
            useValue: environment
        },
    ],
    templateUrl: './blog-item.component.html',
})
export class BlogItemComponent extends Page {
    endpoint: string = '';

    constructor(
        @Inject(DOCUMENT) override readonly document: Document,
        @Inject(ENVIRONMENT) override readonly environment: Environment,
        override readonly gtm: GoogleTagManagerService,
        override readonly meta: Meta,
        override readonly title: Title,
        override readonly router: Router,
        override readonly httpClient: HttpClient,
        override readonly viewportScroller: ViewportScroller,
        override readonly translateService: TranslateService,
        private readonly localize: LocalizeRouterService,
    ) {
        super(document, environment, gtm, meta, title, router, httpClient, viewportScroller, translateService);
    }

    override mapBreadcrumbs(page: PageComponentModel) {
        const currentLang = this.translateService.currentLang;
        return [
            {
                title: 'Blog',
                url  : this.localize.translateRoute(`${(currentLang + '/')}blog`) as string
            },
            {
                title: page.title,
                url  : page.url
            }
        ];
    }

    override pageView(page: PageComponentModel) {
        return {
            ...super.pageView(page),
            page_type: 'blog_item',
        };
    }

    returnUrl(): string {
        const [locale, ...path] = this.router.url
            .split('/')
            .slice(1);

        const [filteredPath, ...hash] = path.join('/').split('#')

        return `${filteredPath}?lang=${locale}`;
    }
}
