<div>
    <app-text [theme]="theme" [block]="blockWithoutButtons"></app-text>

    <div class="mt-4">
        <ng-container *ngFor="let listItem of block.blocks">
            <ng-container [ngSwitch]="listItem.type">

                <ng-container *ngSwitchCase="'buttonComponent'">
                    <app-button blockClass="last:mb-6 mb-2" [theme]="theme" [block]="listItem"></app-button>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="flex align-items-center mb-4 first-of-type:mt-6">
                        <i class="pi pi-check text-green-600 mr-3"></i>
                        <div>
                            <div [class]="'text-sm font-semibold mb-1 ' + theme.current.textTitle"
                                 [innerHTML]="listItem.title  | safeHTML"></div>
                            <div [class]="'text-sm font-normal ' + theme.current.textSubtitle">{{ listItem.subtitle }}</div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
